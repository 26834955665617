import { SubmitHandler } from '@visto-tech/forms';
import { InputWithLabel } from '@visto-tech/forms';
import axios from 'axios';
import ErrorDisplay from 'elements/ErrorDisplay/ErrorDisplay';
import { useAsyncEffect } from 'hooks/useAsyncEffect';
import { head } from 'lodash';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { InvitationAction } from 'static/api';
import { getParamsFromHashUrl } from 'utils/helpers';
import { supabase } from 'utils/supabase';

import { Button } from '../../elements/Button/Button';
import { Text } from '../../elements/Text';
import { useFormManager } from '../../hooks/useFormManager';
import logger from '../../utils/logger';
import {
  SIGN_IN_FORM_VALIDATION,
  SIGN_IN_INITIAL_FORM_DATA,
} from './constants';
import { LoginComponentProps } from './LoginComponentProps';

const SignIn: FC<LoginComponentProps> = observer(({ loginRouter }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResendConfirmationSent, setIsResendConfirmationSent] =
    useState<boolean>(false);

  const formManager = useFormManager({
    formData: SIGN_IN_INITIAL_FORM_DATA,
    validationSchema: SIGN_IN_FORM_VALIDATION,
  });

  useAsyncEffect(async ({ isMounted }: { isMounted: boolean }) => {
    const params = getParamsFromHashUrl();

    if (isMounted && params.error_description) {
      formManager.addError(params.error_description);
    }
  }, []);

  const handleSignIn: SubmitHandler<typeof SIGN_IN_INITIAL_FORM_DATA> = async (
    _,
    data
  ) => {
    const id = toast.loading('Signing in');

    setIsLoading(true);
    formManager.clearErrors();

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

      if (error) {
        toast.remove(id);
        setIsLoading(false);
        formManager.addError(
          error.message === '{}'
            ? 'Sign in failed, please try again.'
            : error.message
        );
        return null;
      }
    } catch (e: unknown) {
      logger.warn('Unknown object thrown: ', e);
    }
  };

  const handleResendConfirmation = async (email: string) => {
    setIsLoading(true);

    try {
      await axios.post('/api/invitation', {
        action: InvitationAction.RESEND_VERIFICATION,
        email,
      });

      toast.success('Verification email sent');

      setIsResendConfirmationSent(true);

      setIsLoading(false);
    } catch (_e) {
      toast.error('Failed to resend verification email');
      setIsLoading(false);
      return null;
    }
  };

  const isEmailNotConfirmedError =
    head(formManager.errors)?.message === 'Email not confirmed';

  let Content = (
    <>
      <Text.Heading variant="form-heading" className="text-center mb-1">
        Welcome to Visto
      </Text.Heading>
      <Text.Paragraph className="text-center mb-5">
        Sign in to your Visto account
      </Text.Paragraph>
      <form
        onSubmit={formManager.createSubmitHandler(handleSignIn)}
        autoComplete="off"
      >
        <InputWithLabel.WithFormManager
          id="login-with-email--email"
          formFieldSetProps={{
            className: 'mt-4',
          }}
          name="email"
          inputProps={{ type: 'email', autoComplete: 'new-password' }}
          formManager={formManager}
          placeholder="Email"
        >
          Email
        </InputWithLabel.WithFormManager>
        <InputWithLabel.WithFormManager
          id="login-with-email--password"
          formFieldSetProps={{
            className: 'mt-4',
          }}
          name="password"
          formManager={formManager}
          inputProps={{ type: 'password', autoComplete: 'new-password' }}
          placeholder="Password"
        >
          Password
        </InputWithLabel.WithFormManager>
        <button
          className="text-center underline align-left mt-4 text-sm"
          onClick={() => loginRouter({ action: 'forgot-password' })}
          type="button"
        >
          Forgot your password?
        </button>
        <Button
          type="submit"
          disabled={isLoading}
          className="mt-6 mx-auto"
          size="large"
          onClick={() => formManager.clearErrors()}
        >
          Log in
        </Button>
      </form>
    </>
  );

  if (isResendConfirmationSent) {
    Content = (
      <>
        <Text.Heading variant="form-heading" className="text-center mb-1">
          Check your email
        </Text.Heading>
        <Text.Paragraph className="text-center mb-1">
          We have sent a new verification email to your inbox. Make sure to
          click on the link in the email to verify your account.
        </Text.Paragraph>
        <Text.Paragraph className="text-center mb-5">
          Make sure to check your junk or spam folder if you can't find the
          email.
        </Text.Paragraph>
        <Button
          type="button"
          className="mt-6 mx-auto"
          size="large"
          onClick={() => {
            formManager.clearErrors();
            setIsResendConfirmationSent(false);
          }}
        >
          Back to Sign In
        </Button>
      </>
    );
  }

  return (
    <>
      <ErrorDisplay formManager={formManager} />
      {isEmailNotConfirmedError && (
        <div className="mb-4 text-center bg-red-200 border border-red-400 rounded-md p-2">
          <Text.Paragraph className="fs14 font-semibold">
            Please verify your email before signing in. Check your inbox for a
            link from hello@visto.ai.
          </Text.Paragraph>
          <Text.Paragraph className="fs14 font-semibold mt-3">
            <button
              className="underline"
              onClick={async () => {
                await handleResendConfirmation(formManager.formData.email);
              }}
            >
              Send new verification email here
            </button>
            .
          </Text.Paragraph>
          <Text.Paragraph className="fs14 font-semibold mt-3">
            Need help?{' '}
            <Link
              href="https://help.visto.ai/en/article/how-to-set-up-your-new-visto-account-w8sa1c/"
              target="_blank"
              className="underline"
            >
              Read instructions here
            </Link>
            .
          </Text.Paragraph>
        </div>
      )}
      {Content}
    </>
  );
});

SignIn.displayName = 'SignIn';

export default SignIn;
